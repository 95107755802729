<template>
  <div class="row">
    <div class="flex xs12">
      <va-file-upload
        dropzone
        type="gallery"
        v-model="gallery"
      />
    </div>
    <div
      class="flex xs12 send-form"
      v-if="!wizard"
    >
      <va-button
        color="primary"
        :disabled="loading || haveErrors"
        @click.prevent="submit"
      >
        <text-loading
          :loading="loading"
          icon="fa fa-save"
        >{{ $t('layout.form.save') }}</text-loading>
      </va-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'training-photos',
  props: {
    training: {
      type: Object,
      required: false,
      default: function () {
        return {
          id: 0,
        }
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return false
      },
    },
    wizard: {
      type: Boolean,
      default: function () {
        return false
      },
    },
  },
  data () {
    return {
      gallery: [],
    }
  },
  computed: {
    haveErrors () {
      return this.gallery.length === 0
    },
  },
  watch: {
    gallery (val) {
      this.filterGallery(val)
    },
  },
  methods: {
    filterGallery (gallery) {
      const maxFileSize = 8388608
      const fileTypes = ['image/gif', 'image/png', 'image/jpeg']
      const currentGallery = gallery.filter(g => {
        const size = g.size <= maxFileSize
        const type = fileTypes.findIndex(t => t === g.type) !== -1
        return size && type
      })

      if (currentGallery.length !== gallery.length) {
        const types = '.png, .jpg, .gif'
        const size = '8MB'
        this.showToast(this.$t('layout.images.not_allowed', { types, size }), {
          icon: 'fa-times',
          duration: 3000,
          position: 'top-right',
        })
        this.gallery = currentGallery.slice(0)
      }
    },
    submit () {
      this.$emit('submit', this.gallery)
    },
  },
}
</script>
<style scoped>
.send-form {
  margin-top: 10px;
}
</style>
